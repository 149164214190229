<template>
  <div class="wrap settingWrqp">
    <div class="back" @click="changeWarningDialog(true)">
      <img src="@/assets/images/back.png" />
      <div class="back-text">返回上一页</div>
    </div>
    <el-form ref="form" :model="form" label-width="100px">
      <!-- <el-radio-group size="mini" v-model="form.week" @click.native.prevent="changeWeek(item)" v-for="(item, index) in form.weeks" :key="index">
        <el-radio-button :label="item.value">{{item.text}}</el-radio-button>
      </el-radio-group> -->
      <el-tabs
        class="tap-top-wrapper taps-card"
        v-model="form.week"
        @tab-click="onTabHandle"
      >
        <el-tab-pane
          v-for="(item, index) in form.weeks"
          :key="index"
          :label="item.text"
          :name="`${item.value + ''}`"
        >
        </el-tab-pane>
      </el-tabs>
      <div class="table-container setting-table">
        <el-table
          :data="form.tableData"
          :height="tableHeight"
          style="width: 100%"
          border
          :row-class-name="tableRowClassName"
          v-if="showTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column align="center">
            <el-table-column
              :show-overflow-tooltip="true"
              label=""
              prop="time"
              align="center"
              min-width="150px"
            >
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="是否显示"
              prop="isVisible"
              align="center"
              width="150px"
            >
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.isVisible"
                  active-value="1"
                  inactive-value="0"
                >
                </el-switch>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            align="center"
            v-for="(item, index) in tableHeaderList1"
            :key="`table_${index}`"
            style="width:200px"
          >
            <template slot="header">
              <el-button size="mini" @click="quickSet(index)"
                >快速设置</el-button
              >
            </template>
            <el-table-column
              :label="item.label"
              align="center"
              width="350px"
            >
              <template slot-scope="scope" >
                <!-- <el-input-number style="width:140px" size="mini" :controls="true" :max="9999" :precision="2" v-model="scope.row[item.prop]"></el-input-number> -->
                <!-- {{item.prop}} -->
                <!-- {{form.tableData[scope.$index][item.prop]}} -->
                <!-- v-model="scope.row[item.prop]" -->
                <!-- form.tableData[scope.$index][item.prop] = $event  -->
                <!-- <el-input
                  style="width: 100px;margin-right:100px;"
                  size="mini"
                  maxlength="7"
                  :value="scope.row[item.prop]"
                  @input="foo($event, scope.$index, item.prop)"
                  @blur="getblur(scope.$index, item.prop)"
                ></el-input> -->
                <el-form :inline="true"  class="demo-form-inline all-price">
                  <el-form-item >
                    <el-input style="width: 100px"
                    size="mini"
                    maxlength="7"
                    placeholder="非会员价"
                    :value="scope.row[item.prop]"
                    @input="foo($event, scope.$index, item.prop)"
                    @blur="getblur(scope.$index, item.prop)"></el-input>
                  </el-form-item>
                  <el-form-item >
                    <el-input style="width: 100px"
                    size="mini"
                    maxlength="7"
                    placeholder="会员价"
                    :value="scope.row[item.propT]"
                    @input="foo($event, scope.$index, item.propT)"
                    @blur="getblur(scope.$index, item.propT)">>
                    </el-input>
                  </el-form-item>
                </el-form>
                <!-- <el-input
                  style="width: 100px"
                  size="mini"
                  maxlength="7"
                  :value="scope.row[item.prop]"
                  @input="foo($event, scope.$index, item.prop)"
                  @blur="getblur(scope.$index, item.prop)"
                ></el-input> -->
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </el-form>
    <div class="button-container">
      <el-button class="btn-set" round size="small" @click="save">{{
        `${$t("save") + form.weekName}`
      }}</el-button>
    </div>
    <!-- 返回上一页提示弹窗 -->
    <warning-dialog
      ref="warningDialog"
      :show="warningDialog.visible"
      @closeDialog="changeWarningDialog(false)"
      @systemSure="systemSureDialog"
    ></warning-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import mixin from "@/mixins/index";
import apis from "@/apis";
import SettingClass from "./settingClass";
import warningDialog from "../dialog/warningDialog.vue";
// import Vue from 'vue'

export default {
  components: {
    warningDialog,
  },
  mixins: [mixin],
  data() {
    return {
      minNumber: 0.01,
      form: new SettingClass("form"), // 实例化一个表单的变量
      rules: new SettingClass("rule", this.Language), // 实例化一个表单的规则
      tableHeaderList: [
        {
          prop: "time",
          align: "center",
          width: "180",
        },
      ],
      show: true,
      tableHeaderList1: [],
      uploadPath: apis.waringRemove,
      warningDialog: {
        visible: false,
      },
      length: 0,
      is_change: false,
      showTable: true,
      allData: [],
      sportsProjectCode: "",
    };
  },
  activated() {
    console.log(this.$route.query);
    if (this.$route.query.id) {
      this.form.sportsProjectCode = this.$route.query.id;
    }
    this.changeWeek1();
  },
  computed: {
    ...mapState(["Language"]),
  },
  methods: {
    foo(value, index, key) {
      const item = this.form.tableData[index];
      // console.log(this.form.tableData);
      // console.log(value);
      // console.log(index);
      // console.log(key);
      if (value) {
        // item[key] = value
        if (value === "#" || value === "#@#") {
          item[key] = value;
          this.$set(this.form.tableData, index, item);
          return;
        }
        // value = Number(value).toFixed(2)
        // const regs = /^\d{4}+\.\d{2}$/
        // if (regs.test(value)) {
        //   item[key] = value
        //   this.$set(this.form.tableData, index, item)
        // }
        item[key] = value;
        // item[key] = Number(item[key]).toFixed(2)
        this.$set(this.form.tableData, index, item);
      } else {
        item[key] = value;
        this.$set(this.form.tableData, index, item);
      }
    },
    getblur(index, key) {
      const item = this.form.tableData[index];
      let value = this.form.tableData[index][key];
      // const reg = /^(([1-9]{1}\d{0,3})|(0{1}))(\.\d{0,2})|^#{1}$/
      const reg = /^(([1-9]{1}\d{0,3})|(0{1}))(\.\d{0,5})$/;
      const regs = /^([1-9]{1}\d{0,3})$|(0{1})$/;
      if (
        regs.test(value)
        || reg.test(value)
        || value === "#"
        || value === "#@#"
      ) {
        if (!value.includes("#")) {
          value = Number(value).toFixed(2);
        }
        item[key] = value;
        this.$set(this.form.tableData, index, item);
      } else {
        item[key] = "";
        this.$set(this.form.tableData, index, item);
      }
    },
    tableRowClassName({ rowIndex }) {
      if (rowIndex === 1) {
        return "warning-row";
      }
      return "";
    },
    onTabHandle(val) {
      this.form.weeks.forEach((item) => {
        if (item.value === val.name) {
          // console.log("value", item)
          this.changeWeek(item);
        }
      });
    },
    /**
     * @function 搜索方法
     */
    searchData() {},
    changeInput(data, index1, index2) {
      console.log(data, index1, index2);
      // const obj = this.form.tableData[index1]
      // console.log(obj[`price${index2}`])
      // this.form = Object.assign({}, this.form, { tableData: this.form.tableData })
      // this.show = false
      // this.$nextTick(() => {
      //   this.show = true
      // })
      // this.is_change = true
    },
    /**
     * @function 改变星期时数据发生变化
     */
    changeWeek(day) {
      if (this.is_change) {
        this.$confirm(this.$t("closeConfirm"), this.$t("tips"), {
          confirmButtonText: this.$t("sure"),
          cancelButtonText: this.$t("cancel"),
          type: "warning",
        })
          .then(() => {
            this.form.weekName = day.text;
            // eslint-disable-next-line array-callback-return
            // this.form.weeks.find((item) => {
            //   if (item.value === this.form.week) {
            //     this.$nextTick(() => {
            //       this.form.weekName = item.text
            //     })
            //   }
            // })
            this.tableHeaderList1 = [];
            this.form.tableData = [];
            this.$http
              .post(apis.selectAdvancedSetting, this.form)
              .then((res) => {
                if (res.data.code === 0) {
                  const rows = res.data.rows;
                  this.allData = res.data.rows;
                  let originData = [];
                  if (rows.length > 0) {
                    this.tableHeaderList1.push({
                      label: rows[0].spaceName,
                      prop: "price",
                      propT: "vipPrice",
                    });
                    originData = [...rows[0].detail];
                    originData.map((item) => {
                      if (item.price === "#") {
                        item.historyPrice = item.historyPrice;
                      } else {
                        item.historyPrice = item.price;
                      }
                      return item;
                    });
                    for (let i = 1; i < rows.length; i += 1) {
                      rows[i].detail.forEach((r, t) => {
                        originData[t][`price${i}`] = r.price;
                        originData[t][`historyPrice${i}`] = r.price === "#" ? r.price : r.price;
                        originData[t][`vipPrice${i}`] = r.vipPrice;
                        originData[t][`historyVipPrice${i}`] = r.vipPrice === "#" ? r.vipPrice : r.vipPrice;
                      });
                      this.tableHeaderList1.push({
                        label: rows[i].spaceName,
                        prop: `price${i}`,
                        propT: `vipPrice${i}`,
                      });
                    }
                  }
                  this.form.tableData = originData;
                  this.form.tableData.sort(this.compare("sort"));
                }
              });
            this.form.week = day.value;
            this.is_change = false;
          })
          .catch(() => {});
      } else {
        // eslint-disable-next-line array-callback-return
        // this.form.weeks.find((item) => {
        //   if (item.value === this.form.week) {
        //     this.form.weekName = item.text
        //   }
        // })
        this.form.weekName = day.text;
        this.tableHeaderList1 = [];
        this.form.tableData = [];
        this.$http.post(apis.selectAdvancedSetting, this.form).then((res) => {
          if (res.data.code === 0) {
            const rows = res.data.rows;
            this.allData = res.data.rows;
            let originData = [];
            if (rows.length > 0) {
              this.tableHeaderList1.push({
                label: rows[0].spaceName,
                prop: "price",
                propT: "vipPrice",
              });
              originData = [...rows[0].detail];
              originData.map((item) => {
                if (item.price === "#") {
                  item.historyPrice = item.historyPrice;
                } else {
                  item.historyPrice = item.price;
                }
                return item;
              });
              for (let i = 1; i < rows.length; i += 1) {
                rows[i].detail.forEach((r, t) => {
                  originData[t][`price${i}`] = r.price;
                  originData[t][`historyPrice${i}`] = r.price === "#" ? r.price : r.price;
                  originData[t][`vipPrice${i}`] = r.vipPrice;
                  originData[t][`historyVipPrice${i}`] = r.vipPrice === "#" ? r.vipPrice : r.vipPrice;
                });
                this.tableHeaderList1.push({
                  label: rows[i].spaceName,
                  prop: `price${i}`,
                  propT: `vipPrice${i}`,
                });
              }
            }
            console.log(this.form.tableData);
            this.form.tableData = [...originData];
            this.form.tableData.sort(this.compare("sort"));
            console.log("pro", this.tableHeaderList1);
            console.log("zheli", this.form.tableData);
          }
        });
        this.form.week = day.value;
      }
    },
    /**
     * @function 改变星期时数据发生变化
     */
    changeWeek1() {
      // eslint-disable-next-line array-callback-return
      this.form.weeks.find((item) => {
        if (item.value === this.form.week) {
          this.form.weekName = item.text;
        }
      });
      this.tableHeaderList1 = [];
      this.form.tableData = [];
      this.$http.post(apis.selectAdvancedSetting, this.form).then((res) => {
        if (res.data.code === 0) {
          const rows = res.data.rows;
          this.allData = res.data.rows;
          let originData = [];
          if (rows.length > 0) {
            this.tableHeaderList1.push({
              label: rows[0].spaceName,
              prop: "price",
              propT: "vipPrice",
            });
            originData = rows[0].detail;
            originData.map((item) => {
              if (item.price === "#") {
                item.historyPrice = item.historyPrice;
              } else {
                item.historyPrice = item.price;
              }
              return item;
            });
            /* eslint-disable */
            for (let i = 1; i < rows.length; i += 1) {
              rows[i].detail.forEach((r, t) => {
                originData[t][`price${i}`] = r.price;
                originData[t][`historyPrice${i}`] =
                  r.price === "#" ? r.price : r.price;
                  originData[t][`vipPrice${i}`] = r.vipPrice;
                originData[t][`historyVipPrice${i}`] =
                  r.vipPrice === "#" ? r.vipPrice : r.vipPrice;
              });
              // console.log(`price${i}`)
              this.tableHeaderList1.push({
                label: rows[i].spaceName,
                prop: `price${i}`,
                propT: `vipPrice${i}`,
              });
            }
          }
          originData = originData.sort(this.compare("sort"));
          this.form = Object.assign({}, this.form, { tableData: originData });
          this.showTable = false;
          this.$nextTick(() => {
            this.showTable = true;
          });
          console.log(this.form);
        }
      });
    },
    // 保存
    save() {
      const tableArr = [...this.form.tableData];
      this.form.tableData.forEach((item, index) => {
        this.form.tableData[index].week = this.form.week;
        console.log(this.form.tableData[index].week);
        for (let i = 1; i < this.allData.length; i += 1) {
          tableArr.push({
            ...item,
            ...{ price: item[`price${i}`],vipPrice:item[`vipPrice${i}`], spaceId: this.allData[i].spaceId },
          });
        }
      });
      console.log(" save", tableArr);
      const flagArr = tableArr.filter((item) => item.price);
      console.log(flagArr);
      if (tableArr.length !== flagArr.length) {
        this.$message({
          message: "请确认所有信息是否填写完整",
          type: "warning",
        });
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$http
            .post(apis.saveAdvancedSetting, { input: tableArr })
            .then((res) => {
              if (res.data.code === 0) {
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
                // this.changeWeek()
              }
            });
        }
      });
    },
    /**
     * @function 返回上一层弹窗
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeWarningDialog(dialogStatus) {
      this.warningDialog.visible = dialogStatus;
    },
    systemSureDialog() {
      this.save();
      this.$router.go(-1);
      this.changeWarningDialog(false);
    },
    // 快速设置
    quickSet(setIndex) {
      console.log(setIndex)
      // console.log(this.tableHeaderList1[setIndex])
      const setValue = this.form.tableData[0][
        this.tableHeaderList1[setIndex].prop
      ];
      const setVipValue = this.form.tableData[0][this.tableHeaderList1[setIndex].propT]
      console.log(setValue)
      console.log(setVipValue)
      for (const item of this.form.tableData) {
        item[this.tableHeaderList1[setIndex].prop] = setValue;
        item[this.tableHeaderList1[setIndex].propT] = setVipValue;
      }
      this.$forceUpdate();
      this.showTable = false;
      this.$nextTick(() => {
        this.showTable = true;
      });
      // console.log(this.form.tableData)
    },
    // 数组对象的某个属性进行排序
    compare(property) {
      // eslint-disable-next-line func-names
      return function (a, b) {
        const value1 = a[property];
        const value2 = b[property];
        return value1 - value2;
      };
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../../assets/styles/mixin.scoped.less";
.tap-top-wrapper {
  margin-left: 30px;
}
.settingWrqp {
  .upload {
    display: inline-block;
    margin-right: 9px;
  }
  .back {
    margin-top: 14px;
    margin-left: 20px;
    margin-bottom: 10px;
    line-height: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      display: inline-block;
    }
    .back-text {
      margin-left: 10px;
      // margin-bottom: 5px;
      font-weight: 400;
      color: rgba(67, 155, 255, 1);
      vertical-align: middle;
      display: inline-block;
    }
  }
  .button-container,
  .button-container .default-button {
    margin: 12px 0 0 20px;
  }
  .el-radio-group {
    margin-left: 20px;
  }
}
</style>
<style lang="less">
.button-container {
  .btn-set {
    border: 1px solid rgba(67, 155, 255, 1) !important;
    color: rgba(67, 155, 255, 1) !important;
  }
}
.el-table--border {
  border: 1px solid #e4e9f1 !important;
}
.setting-table {
  .has-gutter {
    tr:nth-child(2) {
      th {
        background: #eef0f4 !important;
      }
    }
  }
  .all-price{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .el-form-item{
      margin: 0;
    }
  }
}
@import "../../../../assets/styles/mixin.less";
</style>
